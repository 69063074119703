import * as ROUTES from './ROUTES';

export const MENU_HOME = {
  icon: 'mdi-home-outline',
  label: ['Home'],
  route: ROUTES.ROUTE_HOME.path,
};

export const MENU_PRODUCAO = {
  icon: 'mdi-cog-clockwise', //gas-station
  label: ['Ordem Produção A'],
  route: ROUTES.ROUTE_PRODUCAO,
};

export const MENU_PRODUCAO_B = {
  icon: 'mdi-cog-clockwise', //gas-station
  label: ['Ordem Produção B'],
  route: ROUTES.ROUTE_PRODUCAO_B,
};

export const MENU_PRODUCAO_C = {
  icon: 'mdi-cog-clockwise', //gas-station
  label: ['Ordem Produção C'],
  route: ROUTES.ROUTE_PRODUCAO_C,
};

export const MENU_SECADOR = {
  icon: 'mdi-cog', //gas-station
  label: ['Secador'],
  route: ROUTES.ROUTE_SECADOR,
};

export const MENU_POSICAO_ARMAZEM = {
  icon: 'mdi-package-variant-closed', //gas-station
  label: ['Posição Armazém'],
  route: ROUTES.ROUTE_POSICAO_ARMAZEM,
};

export const MENU_TRANSFERENCIA_PRODUCAO = {
  icon: 'mdi-cog-transfer', //gas-station
  label: ['Transferência Producao'],
  route: ROUTES.ROUTE_TRANSFERENCIA_PRODUCAO,
};






// ORDEM DO MENU

export default [
  MENU_HOME,
  MENU_PRODUCAO,
  MENU_PRODUCAO_B,
  MENU_PRODUCAO_C,
  MENU_SECADOR,
  MENU_POSICAO_ARMAZEM,
  // MENU_TRANSFERENCIA_PRODUCAO,
];

export function menuMapper(componentInstance, menus) {
  return [].concat(menus).map((menu) => ({
    ...menu,
    label: String(...menu.label),
  }));
}
