<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    filled
    :items="dados"
    item-text="ablnrocontrole"
    :outlined="outlined"
    :append-icon="icon"
    @click:append="action"
    @keydown.enter="$_selecionaPrimeiro"
    background-color="white"
    :class="{['mb-5']: true, ['upper-text']: upper}"
    hide-details
    ref="focusinput">
    <template v-slot:item="dados">
      <!-- Voltar o return object? -->
      <template>
        <v-list-item-content class="divisao">
          <v-list-item-subtitle>
              <div class="spaceout">
                <b>
                  {{dados.item.ablnrocontrole}} 
                </b>
                <b> 
                  - 
                </b>
                <b>
                  {{$_formataData(dados.item.abldataabertura,'DD/MM/YYYY')}}
                </b>
              </div><br/>
                {{dados.item.abldescricao}}
                <br/>
                {{dados.item.ablgranulometria}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
<script>

import { OPERADOR_STORAGE } from '@/constants/STORAGE';

import moment from 'moment-timezone';

export default {
  name: 'CoreBtnAutoComplete',

  // ESTA CONFIGURADO PARA ABERTURALOTE | | | | | | | | | | | | | | | | | | | | | | | 

  props: {
    icon: {
      type: String,
      default: 'mdi-dots-horizontal-circle-outline',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    redirect: {
      type: Object,
      default: null,
    },
    modal: {
      type: Boolean,
      default: null,
    },
    dados: {
      type: Array,
      default: (() => ([])),
    },
    itemLabel: {
      type: String,
      default: '',
    },
    temvazio: {
      type: Boolean,
      default: false,
    },
    paramappend: {
      default: null,
    },
    action: {
      type: Function,
      default: (() => {})
    },
    expand: {
      type: Boolean,
      default: (() => {})
    },
    upper: {
      type: Boolean,
      default: (() => {})
    }
  },

  data() {
    return {
      txtInput: '',
      select: null,
    };
  },

  methods: {
    $_trataClique() {
      sessionStorage.setItem(OPERADOR_STORAGE.tipo, this.$props.paramappend);
      if (this.$props.redirect) {
        this.$router.replace({
          ...this.$route,
          name: this.$props.redirect.name,
        });
      } else {
        this.$refs.focusinput.focus();
      }
    },

    $_validaFrota() {
      this.$emit('frotaAlterada', this.txtInput);
    },

    $_returnValor() {
      console.log(this.$refs.focusinput.value);
      this.$refs.focusinput.value = this.$refs.focusinput.valu.ablnrocontrole;
    },

    $_formataData(date, mascara) {
      return moment.tz(date, 'America/Sao_Paulo').local().format(mascara);
    },

    $_selecionaPrimeiro() {
      if (this.$refs.focusinput.$children[1].listIndex === -1) {
        this.$refs.focusinput.$children[1].listIndex = 0;
      }
    },
  },

  beforeMount() {
    this.select = this.$props.dados;
    if (this.$props.temvazio) {
      this.select.push({ [this.$props.itemLabel]: 'vazio' });
    }
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

/* CHANGELOG */
/* 
  V2: Adicionado Action aos props, para alimentar a função de clique externamente.
*/

<style scoped>
  .spaceout {
    display: flex;
    justify-content: space-between;
  }

  .divisao {
    border-bottom: 1px solid #dddddd;
  }

  .upper-text >>> input {
    text-transform: uppercase !important;
  }

</style>
