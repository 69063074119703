<template>
  <v-app-bar :color="$_color" :dark="$_dark" app>
    <template v-if="!$_isMobile || !showSearch">
      <template v-if="$_isMobile">
        <dialog-btn height=45 color="green" class="botaoinferior"
        v-if='backTo && withDialog'
        titulo="Descartar Alterações?"
        salvar='Cancelar'
        sair="Continuar"
        icon="mdi-alert-circle-outline"
        buttonIcon="mdi-arrow-left"
        @sair="$_novoFormulario()">
        <template #conteudo>
          <div>
            Qualquer alteração realizada será descartada.
          </div>
        </template>
        </dialog-btn>
        <v-btn v-else-if="backTo" :to="backTo" exact class="btn-left">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-app-bar-nav-icon v-else @click="$_onClickNavIcon" />
      </template>

      <v-toolbar-title>
        <slot />
      </v-toolbar-title>

      <v-spacer />
    </template>

    <template v-if="searchable && (!$_isMobile || showSearch)">
      <v-btn v-if="$_isMobile" @click="$_onClickCloseSearch" icon>
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>

      <v-text-field clearable hide-details solo flat autofocus
        v-model="search"
        :placeholder="('labels.search')"
        :rounded="!$_isMobile"
        :light="!$_isMobile"
        :dense="!$_isMobile"
      />

      <v-spacer v-if="!$_isMobile" />
    </template>

    <slot name="actions" v-if="!$_isMobile || !showSearch" />

    <div>
      <v-badge dot
        v-if="searchable && ($_isMobile && !showSearch)"
        color="red"
        offset-x="16"
        offset-y="16"
        :value="!!search"
      >
        <v-btn @click="$_onClickOpenSearch" icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-badge>
    </div>

    <div>
      <v-tooltip v-if="filterable && (!$_isMobile || !showSearch)" bottom>
        <template #activator="{ attrs, on }">
          <v-badge dot
            color="red"
            offset-x="16"
            offset-y="16"
            :value="isFiltered"
          >
            <v-btn icon
              v-bind="attrs"
              v-on="on"
              @click="$_onClickFiltersBtn"
            >
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </v-badge>
        </template>

        <span>Filtros</span>
      </v-tooltip>
    </div>

    <span class="mr-n3" />
  </v-app-bar>
</template>

<script>

import { ROUTE_LOTE } from '@/constants/ROUTES';

import { CADLOTE_STORAGE } from '@/constants/STORAGE';
import { GENERIC_QUERIES } from '@/constants/QUERY_FILTERS';

import { getQueryValue, setQueryValue } from '@/services/query-string';

import ToggleFiltersEvent from '@/events/ToggleFiltersEvent';
import ToggleNavigationDrawerEvent from '@/events/ToggleNavigationDrawerEvent';
import DialogBtn from '../../components/core/dialog-btn/Index.vue';

export default {
  name: 'LayoutDefaultAppbar',

  props: {
    DialogBtn,
    withDialog: Boolean,
    searchable: Boolean,
    filterable: Boolean,
    filters: Array,
    backTo: [String, Object],
  },

  components: {
    DialogBtn,
  },

  data() {
    return {
      search: null,
      showSearch: false,
      isFiltered: false,
    };
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    $_dark() {
      return !(this.$_isMobile && this.showSearch);
    },

    $_color() {
      return this.$_dark ? 'primary' : 'white';
    },
  },

  methods: {
    $_onClickNavIcon() {
      ToggleNavigationDrawerEvent.emit();
    },

    $_onClickOpenSearch() {
      this.showSearch = true;
    },

    $_onClickCloseSearch() {
      this.showSearch = false;
    },

    $_onClickFiltersBtn() {
      ToggleFiltersEvent.emit();
    },

    async $_novoFormulario() {
      sessionStorage.setItem(CADLOTE_STORAGE.idabastecimento, '0');
      ['frota', 'operacao', 'data', 'operadora', 'odometro', 'quantidade',
        'operadorb', 'origem', 'descricao', 'propriedade', 'produto', 'atividade',
      ].forEach((item) => {
        sessionStorage.removeItem(CADLOTE_STORAGE[item]);
      });
      this.$router.replace({
        ...this.$route,
        name: ROUTE_LOTE.name,
      });
    },

    async $_retomarFormulario() {
      this.retomar = false;
      this.$router.replace({
        ...this.$route,
        name: ROUTE_LOTE.name,
      });
    },

  },

  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.search = getQueryValue(GENERIC_QUERIES.search, null);

        if (!this.filters?.length) return;

        this.isFiltered = this.filters
          .some((filter) => getQueryValue(filter, false));
      },
    },

    search(search) {
      clearTimeout(this.$_searchTimeout);
      this.$_searchTimeout = setTimeout(() => {
        setQueryValue(GENERIC_QUERIES.search, search);
      }, 1000);
    },
  },
};
</script>

<style scoped>
  .btnleft {
    border: 1px solid red;
  }
</style>
