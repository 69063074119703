<template>
  <v-text-field :rounded="rounded"
    v-bind="$attrs"
    v-on="$listeners"
    :outlined="outlined"
    hide-details
    :dense="!$_isMobile"
    v-mask="mask"
    class="mb-5"
    style="background-color: white;"
    @change="$_swap"
  />
</template>

<script>
export default {
  name: 'CoreTextField',

  props: {
    outlined: {
      type: Boolean,
      default: true,
    },

    rounded: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  data() {
    return {
      mask: '##:##'
    }
  },

  methods: {
    $_swap(e) {
      console.log(e);
      let valor = [...e];
      if (!valor[0]) {
        valor[0] = 0;
      }
      if (!valor[1]) {
        valor.push(0);
      }
      if (!valor[3]) {
         valor.push(':');
         valor.push(0);
      }
      if (!valor[4]) {
         valor.push(0);
      }
      if (typeof valor !== 'string') {
        valor = valor.join('');
      }
      const digitosA = `${valor[0]}${valor[1]}`;
      const digitosB = `${valor[3]}${valor[4]}`;
      console.log(digitosA, digitosB);
      if (Number(digitosA) > 23) {
        valor = `23:${valor[3]}${valor[4]}`;
      }
      if (Number(digitosB) > 59) {
        valor = `${valor[0]}${valor[1]}:59`;
      }
      this.$emit('valor', valor);
    }
  }
};
</script>
