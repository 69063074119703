<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :dense="!$_isMobile"
    filled
    :items="dados"
    :item-text="itemLabel"
    :outlined="outlined"
    :append-icon="icon"
    @click:append="action"
    background-color="white"
    class="mb-5"
    return-object
    hide-details
    ref="focusinput"
  />
</template>
<script>

import { OPERADOR_STORAGE } from '@/constants/STORAGE';

export default {
  name: 'CoreBtnAutoComplete',

  props: {
    icon: {
      type: String,
      default: 'mdi-dots-horizontal-circle-outline',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    redirect: {
      type: Object,
      default: null,
    },
    modal: {
      type: Boolean,
      default: null,
    },
    dados: {
      type: Array,
      default: (() => ([])),
    },
    itemLabel: {
      type: String,
      default: '',
    },
    temvazio: {
      type: Boolean,
      default: false,
    },
    paramappend: {
      default: null,
    },
    action: {
      type: Function,
      default: (() => {})
    }
  },

  data() {
    return {
      txtInput: '',
      select: null,
    };
  },

  methods: {
    $_trataClique() {
      sessionStorage.setItem(OPERADOR_STORAGE.tipo, this.$props.paramappend);
      if (this.$props.redirect) {
        this.$router.replace({
          ...this.$route,
          name: this.$props.redirect.name,
        });
      } else {
        this.$refs.focusinput.focus();
      }
    },

    $_validaFrota() {
      this.$emit('frotaAlterada', this.txtInput);
    },
  },

  beforeMount() {
    this.select = this.$props.dados;
    if (this.$props.temvazio) {
      this.select.push({ [this.$props.itemLabel]: 'vazio' });
    }
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

/* CHANGELOG */
/* 
  V2: Adicionado Action aos props, para alimentar a função de clique externamente.
*/
